import { useContext, useEffect, useState } from "react";
import { useApi } from "./useApi";
import { AuthContext } from "components/contexts/AuthContext";

const useStoreDelivery = storeId => {
	const api = useApi();
	const { authenticated } = useContext(AuthContext);
	const [deliveryRestrictions, setDeliveryRestrictions] = useState([]);
	const [loading, setLoading] = useState(false);

	const checkIfConditionMet = (priceTiers = [], cart) => {
		if (!priceTiers?.length || !cart) return [];
		const { totalPrice, totalItems } = cart;

		// sort price tiers
		const sortedPriceTiers = priceTiers
			.filter(tier => tier.is_active)
			.sort((a, b) => a.shipping_cost - b.shipping_cost);

		// filter matched tiers
		const matchedTiers = sortedPriceTiers.filter(tier => {
			const amountCondition =
				!!tier.purchase_amount && totalPrice >= tier.purchase_amount;
			const quantityCondition =
				!!tier.purchase_quantity && totalItems >= tier.purchase_quantity;

			if (tier.purchase_condition === "OR") {
				return amountCondition || quantityCondition;
			} else if (tier.purchase_condition === "AND") {
				return amountCondition && quantityCondition;
			}
			return false;
		});

		return matchedTiers;
	};

	async function getUserDeliveryAddressesByCart(cart, addresses = []) {
		const restrictions = await loadUserAddressesRestrictions();
		const deliveryData = addresses.map(address => {
			const deliveryInfo = getDeliveryInfoByUserAddressId(
				address.id,
				restrictions,
			);
			if (!deliveryInfo) {
				return { ...address, isDeliveryActive: false };
			}
			const { priceTiers = [], deliveryDays = [] } = deliveryInfo;
			if (!priceTiers.length) {
				return { ...address, isDeliveryActive: false };
			}
			const eligibleTiers = checkIfConditionMet(priceTiers, cart);

			return {
				...address,
				isDeliveryActive: deliveryInfo.isDeliveryActive,
				isConditionMet: !!eligibleTiers?.length,
				eligibleTiers: eligibleTiers,
				priceTiers: priceTiers,
				deliveryDays: deliveryDays,
			};
		});
		return deliveryData;
	}

	const getCartDeliveryInfoByCityId = async (cityId, cart) => {
		const deliveryInfo = await getDeliveryInfoByCityId(cityId);
		if (!deliveryInfo) {
			return { isDeliveryActive: false };
		}
		const { priceTiers = [], deliveryDays = [] } = deliveryInfo;
		if (!priceTiers.length) {
			return { isDeliveryActive: false };
		}

		const eligibleTiers = checkIfConditionMet(priceTiers, cart);
		return {
			isDeliveryActive: deliveryInfo.isDeliveryActive,
			isConditionMet: !!eligibleTiers.length,
			eligibleTiers: eligibleTiers,
			priceTiers: priceTiers,
			deliveryDays: deliveryDays,
		};
	};

	const getDeliveryInfoByCityId = async cityId => {
		let params = {
			city_id: cityId,
			store_id: storeId,
		};
		try {
			setLoading(true);
			const res = await api.getStoreCityDeliveryInfo(params);
			if (res.ok) {
				const data = {
					cityId: res.data.city_id,
					cityHebName: res.data.heb_name,
					isDeliveryActive: res.data.active,
					deliveryDays: res.data?.delivery_days ?? [],
					isFreeDelivery: res.data.is_free_delivery,
					freeDeliveryCondition: res.data.free_delivery_condition,
					priceTiers: res.data.price_tiers,
				};
				return data;
			} else {
				throw res.error.message;
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const getDeliveryInfoByUserAddressId = (addressId, restrictions) => {
		if (!restrictions) {
			restrictions = deliveryRestrictions;
		}

		const info = restrictions.find(
			restriction => restriction.user_address_id == addressId,
		);
		if (!info) {
			console.log("No delivery info found for address");
			return;
		}
		const data = {
			cityId: info.city_id,
			cityHebName: info.heb_name,
			isDeliveryActive: info.active,
			deliveryDays: info?.delivery_days ?? [],
			isFreeDelivery: info.is_free_delivery,
			freeDeliveryCondition: info.free_delivery_condition,
			priceTiers: info.price_tiers,
		};
		return data;
	};

	const loadUserAddressesRestrictions = async () => {
		const res = await api.getUserShippingRestrictions(storeId);
		if (res.ok) {
			setDeliveryRestrictions(res.data?.items);
			return res.data?.items;
		}
	};

	return {
		loading,
		deliveryRestrictions,
		getUserDeliveryAddressesByCart,
		getDeliveryInfoByCityId,
		getCartDeliveryInfoByCityId,
		checkIfConditionMet,
	};
};

export default useStoreDelivery;
