import { CheckCircleIcon, TruckIcon } from "@heroicons/react/outline";

import {
	ShoppingCartIcon,
	TruckIcon as TruckIconSold,
} from "@heroicons/react/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { classNames } from "util/common";

export const StoreShippingRestrictions = ({
	deliveryData,
	singleStoreView = false,
	loading = false,
}) => {
	const containerRef = useRef(null);
	const [isColumnView, setIsColumnView] = useState(true);

	useEffect(() => {
		const updateWidth = () => {
			if (!containerRef.current) {
				return;
			}
			const parentWidth = containerRef.current.parentElement.offsetWidth;
			if (parentWidth < 500) {
				setIsColumnView(true);
			} else {
				setIsColumnView(false);
			}
		};

		updateWidth();
		window.addEventListener("resize", updateWidth);
		return () => {
			window.removeEventListener("resize", updateWidth);
		};
	}, [containerRef]);

	if (loading) {
		return (
			<div className="w-full bg-jane-100 h-[73px] rounded-md animate-pulse duration-75"></div>
		);
	}

	if (!deliveryData) {
		return null;
	}

	const {
		deliveryDays = [],
		isDeliveryActive = false,
		eligibleTiers = [],
		priceTiers = [],
	} = deliveryData;

	if (!isDeliveryActive) {
		return (
			<div className="border font-semibold bg-jane-200 text-jane-600 p-2 text-center border-jane-600 rounded-md leading-none flex flex-col items-center">
				<span className="relative">
					<TruckIconSold className="w-10 h-10 text-jane-600" />
					<MdCancel className="w-5 h-5 text-red-800 bg-jane-200 rounded-full absolute top-0 left-0" />
				</span>
				אין משלוחים
			</div>
		);
	}
	const dayNames = ["א", "ב", "ג", "ד", "ה", "ו", "ש"];
	const today = new Date().getDay();
	const todayDelivery = deliveryDays[today];
	return (
		<div
			ref={containerRef}
			className={`text-base text-gray-700 mt-2 flex gap-2 md:gap-4 ${
				isColumnView ? "flex-col" : "flex-row"
			}  `}
		>
			{!singleStoreView &&
				(eligibleTiers.length === 0 ? (
					<div className="border font-semibold bg-jane-200 text-jane-600 p-2 text-center border-jane-600 rounded-md leading-none flex flex-col items-center">
						<span className="relative">
							<ShoppingCartIcon className="w-10 h-10 text-jane-600" />
							<FaExclamationCircle className="w-5 h-5 text-yellow-600 bg-jane-200 rounded-full p-[1px] absolute top-0 left-0" />
						</span>
						סל לא עומד <br className={`${isColumnView ? "hidden" : "block"}`} />{" "}
						בתנאי משלוח
					</div>
				) : (
					<div
						className={`flex flex-col items-center min-w-[100px] text-center justify-center font-semibold text-emerald-600 bg-jane-100 border-emerald-500 border rounded-md px-1 ${
							isColumnView ? "f-full" : "w-fit"
						}`}
					>
						<CheckCircleIcon className="w-10 h-10" />
						<span>
							{eligibleTiers?.[0]?.shipping_cost === 0
								? "חינם"
								: `₪${eligibleTiers?.[0]?.shipping_cost}`}
						</span>
					</div>
				))}
			{deliveryDays.some(day => day.is_active) && (
				<div className="flex flex-col">
					<span className="font-semibold ml-2">ימי שילוח:</span>
					<span className="border border-jane-300 rounded-md flex overflow-hidden w-fit">
						{deliveryDays.map((day, index) => (
							<Fragment key={index}>
								<span
									className={classNames(
										index === today && day.is_active
											? "bg-emerald-500 text-white font-bold"
											: day.is_active
											? "bg-jane-500 font-bold text-white"
											: index === today
											? "bg-jane-200 text-jane-600"
											: "text-jane-400",
										"w-6 text-center",
									)}
								>
									{dayNames[index]}
								</span>
								{index !== deliveryDays?.length - 1 && (
									<span className="h-6 w-[1px] bg-jane-300" />
								)}
							</Fragment>
						))}
					</span>
				</div>
			)}
			{(todayDelivery?.next_day_delivery_end_time ||
				todayDelivery?.same_day_delivery_end_time) && (
				<div>
					{todayDelivery?.same_day_delivery_end_time && (
						<div>
							<span className="font-semibold ml-2">
								משלוח להיום, הזמנות עד:
							</span>
							<span>{todayDelivery.same_day_delivery_end_time}</span>
						</div>
					)}
					{todayDelivery?.next_day_delivery_end_time && (
						<div>
							<span className="font-semibold ml-2">משלוח למחר, הזמנות עד:</span>
							<span>{todayDelivery.next_day_delivery_end_time}</span>
						</div>
					)}
				</div>
			)}

			{!!priceTiers?.length && (
				<div className="flex flex-col gap-2">
					<span className="font-semibold">כל התנאים:</span>
					{priceTiers.map((tier, index) => {
						if (!tier?.is_active) {
							return;
						}
						return (
							<div
								key={index}
								className={classNames("flex gap-1 font-semibold text-jane-700")}
							>
								<span className="whitespace-nowrap h-fit items-center flex gap-1">
									<TruckIcon className="w-5 h-5" />
									{tier?.shipping_cost === 0
										? "חינם"
										: `₪${tier?.shipping_cost}`}{" "}
									-
								</span>
								<span className="font-medium">
									בקנייה מעל{" "}
									{!!tier?.purchase_amount && "₪" + tier?.purchase_amount}
									{!!tier?.purchase_amount &&
										!!tier?.purchase_quantity &&
										tier?.purchase_condition === "AND" &&
										" ו-"}
									{!!tier?.purchase_amount &&
										!!tier?.purchase_quantity &&
										tier?.purchase_condition === "OR" &&
										" או "}
									{tier?.purchase_quantity &&
										tier?.purchase_quantity * 10 + " גרם"}
								</span>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
